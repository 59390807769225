<template>
  <div class="login">

    <v-row no-gutters>
      <v-col cols="12" md="7" class="login-bg mobile-hidden"></v-col>
      <v-col cols="12" md="5">

        <div class="text-center">
          <a href="https://www.soocel.com" class="d-flex justify-center align-center">
            <img src="@/assets/images/soocel-logo.png" height="45" class="logo" />
          </a>
        </div>

        <h2 class="text-center">Connect Your Social Channels</h2>

        <div class="d-flex justify-space-around connect-buttons">
          <div class="connect-box d-flex align-center">
            <v-icon class="mr-2" color="#ff0101" large>mdi-youtube</v-icon>
            <p>YouTube</p>
            <v-btn depressed outlined small color="secondary" @click="goConcent()" v-if="!this.ytConnected">Connect</v-btn>
            <v-btn depressed outlined small color="success" @click="goConcent()" v-else>
              <v-icon small left>mdi-check-circle</v-icon> Connected
            </v-btn>
          </div>

          <div class="connect-box d-flex align-center">
            <img class="mr-2" src="@/assets/images/icon-instagram.svg" width="26" />
            <p>Instagram</p>

            <v-btn depressed outlined small :color="this.instaConnected ? 'success' : 'secondary'" @click="instaDialogue = true">
              <v-icon small left v-if="this.instaConnected">mdi-check-circle</v-icon>
              {{ this.instaConnected ? 'Connected' : 'Connect'}}
            </v-btn>
          </div>
        </div>

        <div class="d-flex align-center justify-center mt-8" v-if="this.instaConnected || this.ytConnected">
          <v-btn depressed dark large color="primary" style="width: 250px;" to="/dashboard">Continue</v-btn>
        </div>

      </v-col>
    </v-row>

    <v-dialog v-model="instaDialogue" width="500">
      <v-card>
        <v-card-text class="pa-8">
          <v-form v-model="instaInfoValid" v-on:submit.prevent="onSubmit" lazy-validation ref="instaInfo">
            <label class="label">
              Instagram Username <br>
              <small>eg. </small>
            </label>
            <v-text-field
              v-model="instaProfile.username"
              placeholder="instagram_username"
              :rules="rules.username"
              solo
            ></v-text-field>
            <v-btn v-if="instaProfile.graphql" class="mt-1" color="primary" :loading="instaLoading" @click="fetchInstaProfile()">Submit</v-btn>
            <v-btn v-else class="mt-1" color="primary" :loading="instaLoading" @click="fetchInstaProfile()">Submit</v-btn>
          </v-form>
        </v-card-text>
      </v-card>
    </v-dialog>

  </div>
</template>

<script>
import axios from 'axios'
import { updateInsta, getUserInfo } from '@/api/user'

export default {
  data () {
    return {
      channelname: null,
      publishedAt: null,
      subscribers: null,
      videoCount: null,
      viewCount: null,
      authCode: '',
      loading: false,
      loadingbtn: false,
      ytConnected: false,
      instaConnected: false,
      instaDialogue: false,
      instaLoading: false,
      instaProfile: { username: '' },
      instaRedirectUrl: `${window.location.origin}/auth/instagram`,
      authConsentUrl: '',
      code: '',
      valid: true,
      instaInfoValid: true,
      rules: {
        username: [
          v => !!v || 'Instagram username is required',
          v => (/^@/.test(v) || /^http/.test(v)) ? 'Enter exact username' : !!v
        ]
      }
    }
  },
  mounted: async function () {
    if (this.$route.query.code) {
      this.concentauthCode()
    } else {
      await this.getUserData()
      this.getAuthConsentUrl()
    }
  },
  methods: {
    goConcent () {
      window.location.href = this.authConsentUrl
    },
    async getUserData () {
      this.loading = true
      const data = await getUserInfo()
      this.loading = false
      this.user = {
        id: data.info.uuid,
        mobile: data.info.mobile,
        email: data.info.email,
        ifsc: data.info.ifsc ? data.info.ifsc.toUpperCase() : null,
        pan: data.info.pan ? data.info.pan.toUpperCase() : null,
        name: data.info.name,
        accountNumber: data.info.account_number,
        accountName: data.info.account_name,
        companyName: data.info.company_name,
        avatar: data.info.avatar,
        ytChannel: data.ytChannel,
        instaChannel: data.instaChannel,
        status: data.info.status
      }
      window.localStorage.setItem('status', data.info.status)
      window.localStorage.setItem('user', JSON.stringify(this.user))
    },
    async fetchInstaProfile () {
      if (this.$refs.instaInfo.validate()) {
        // this.instaLoading = true
        this.instaProfile.edge_followed_by = 0
        this.instaProfile.edge_follow = 0
        this.instaProfile.edge_owner_to_timeline_media = 0
        this.updateInstaInfo()
        // const response = await axios.get(`https://www.instagram.com/${this.instaProfile.username}/?__a=1&__d=dis`)
        // if (response.graphql) {
        //   this.instaProfile = response.graphql.user
        // } else {
        //   this.instaProfile.edge_followed_by = 0
        //   this.instaProfile.edge_follow = 0
        //   this.instaProfile.edge_owner_to_timeline_media = 0
        //   this.updateInstaInfo()
        // }
        // this.instaLoading = false
      }
    },
    async updateInstaInfo () {
      this.instaLoading = true
      const response = await updateInsta(this.instaProfile)
      if (response.success) {
        this.instaConnected = true
        this.$snackbar({
          message: response.message
        })
        this.getUserData()
      } else {
        this.$snackbar({
          message: response.message,
          color: 'error',
          timeout: 5000
        })
      }
      this.instaLoading = false
      this.instaDialogue = false
    },
    async getAuthConsentUrl () {
      try {
        const config = {
          headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + window.localStorage.getItem('user-token')
          }
        }
        const response = await axios.post('/api/youtube/authConsentUrl', { page: 0 }, config)
        if (response.data.success) {
          this.authConsentUrl = response.data.channelauthurl
        } else {
          this.$snackbar({
            color: 'error',
            message: response.data.message
          })
        }
      } catch (error) {
        this.$snackbar({
          color: 'error',
          message: 'Something went wrong'
        })
      }
    },
    concentauthCode () {
      this.authCode = this.$route.fullPath
      var reg = /code/
      var regResult = reg.test(this.authCode)
      if (regResult) {
        this.code = this.$route.query.code
        this.code = decodeURIComponent(this.code)
      }
      this.loginLastStep()
    },
    async loginLastStep () {
      try {
        const config = {
          headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + window.localStorage.getItem('user-token')
          }
        }
        this.loading = true
        const response = await axios.post('/api/youtube/youtubeLogin', { code: this.code, page: 0 }, config)
        if (response.data.success) {
          this.ytConnected = true
          this.$snackbar({
            message: response.data.message
          })
        } else {
          this.$snackbar({
            color: 'error',
            message: response.data.message
          })
          this.loading = false
        }
      } catch (error) {
        console.log(error)
        this.$snackbar({
          color: 'error',
          message: 'Something went wrong'
        })
        this.loading = false
      }
      await this.getUserData()
      this.getAuthConsentUrl()
      // this.$router.push('/user/connect')
    }
  }
}
</script>

<style scoped>
  .connect-buttons {
    margin: 50px 0 0;
  }
  .connect-box {
    border: 1px solid #eee;
    padding: 5px 10px;
    border-radius: 3px;
  }
  .connect-box p {
    margin: 0 20px 0 0;
  }
</style>
